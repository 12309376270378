import { applyInfoItem } from "@/api/config/applyUse"
import { defineStore } from "pinia"
import { addCustomer as addCustomerApi,} from "@/api/config/applyUse"

export const useCustomerStore = defineStore("customer", () => {
  // 添加角色
  const addCustomer = async (data: applyInfoItem) => {
    const res = await addCustomerApi(data)
    return res
  }
  return { addCustomer }
})
