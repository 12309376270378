import request from "./request"
import { ApiResponse } from "../type"
// 定义添加客户信息类型
interface applyInfoItem {
  name : string;
  phoneNo : string;
  orgName : string;
  dutyName : string;
  demandSituation : string;
  area : string;
  remark ?: string
}
 // 添加客户信息
export const addCustomer = (data: applyInfoItem): Promise<ApiResponse> => {
  return request.post("/api/joinus/recordCustomer", data)
} 

